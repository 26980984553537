<template>
  <div v-if="user" class="container text-center">

    <div class="row">

      <div class="col-12 col-lg-6 offset-lg-3">

        <!-- profile card -->
        <div class="card profile-card">
          <div class="card-body">
            <ProfileFilmsViewedGraphic class="profile-films-viewed-graphic" :user=user width="100%" height="100%" color="LightBlue"></ProfileFilmsViewedGraphic>
            <div class="profile-info-layered-over-graphic">
              <ProfilePicture :user=user :size="100" :editable="true"></ProfilePicture>
              <ProfileName class="name-text" :user="user"></ProfileName>
            </div>
          </div>
        </div>

        <!-- films viewed card -->
        <div class="card films-viewed-card">
          <div class="card-header">
            <div v-if="showExpandedFilmList" class="btn btn-sm btn-show-more" type="button" @click="showExpandedFilmList = !showExpandedFilmList">
              <i :class="showExpandedFilmList? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
              <span v-text="showExpandedFilmList? ' show less ' : ' show more '" aria-hidden="true"></span>
            </div>
            films viewed
          </div>
          <div class="card-body" :class="`${showExpandedFilmList? 'expanded' : 'not-expanded'}`">
            <LoadingSpinner v-if="!filmsViewed" class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>
            <div v-else v-for="decade in filmDecadeMap.keys()" :key="decade" class="decade">
              <b>{{decade}}s</b>
              <div class="film-list">
                <div v-for="film in filmDecadeMap.get(decade)" :key="film._id" >
                  <FilmLink :film_name="film.name" :film_id="film._id" single_line="true" no_strikethrough="true"></FilmLink>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="btn btn-sm btn-show-more" type="button" @click="showExpandedFilmList = !showExpandedFilmList">
              <i :class="showExpandedFilmList? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
              <span v-text="showExpandedFilmList? ' show less ' : ' show more '" aria-hidden="true"></span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>


<script>
import ProfilePicture from "@/components/profile/ProfilePicture.vue";
import ProfileFilmsViewedGraphic from "@/components/profile/ProfileFilmsViewedGraphic.vue";
import axios from "axios";
import FilmLink from "@/components/FilmLink.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ProfileName from "@/components/profile/ProfileName.vue";
import {userStore} from "@/userStore";

export default {
  name: "FriendPage",
  components: {ProfileName, LoadingSpinner, FilmLink, ProfileFilmsViewedGraphic, ProfilePicture},
  props: ['user_id'],
  computed: {
    isAdmin() {
      return this.user.isAdmin;
    },
    isTester() {
      return this.user.isTester;
    }

  },
  data() {
    return {
      filmsViewed: null,
      filmDecadeMap: new Map(),
      showExpandedFilmList: false,
      user: (
          userStore.user.friends?.find(friend => (friend._id === this.user_id)) ||
          userStore.user.friends_waiting_for_approval?.find(friend => (friend._id === this.user_id))
      )
    }
  },
  async created() {

    // if we can't find this user, go to the friends page
    if ( !this.user ) {
      this.$router.push({name: 'FriendsPage'});
      return;
    }

    axios.post(
        '/api/film/names',
        {
          film_ids: this.user.viewed
        })
        .then((response) => {
          //console.log(response);
          this.filmsViewed = response.data;
          this.filmsViewed.sort((filmA, filmB) => {
            return Number(filmB.year) - Number(filmA.year);
          });

          this.filmsViewed.forEach((film) => {
            const decade = Math.floor(Number(film.year) / 10) * 10;
            //console.log(decade);

            let films = this.filmDecadeMap.has(decade)? this.filmDecadeMap.get(decade) : [];
            films.push(film);
            this.filmDecadeMap.set(decade, films);
          });
        })
        .catch((err) => {
          this.filmsViewed = [];
          console.log(err);
        })

  },
  methods: {
  }
}
</script>



<style scoped>

body {
  background-color: aliceblue;
}

.card {
  background-color: aliceblue;
  border-color: cornflowerblue;

  margin-top: 20px;
  margin-bottom: 20px;

  .card-header {
    font-size: x-large;
    font-weight: 500;
    background-color: revert;
    border-style: none;
  }
}


.profile-card {
  isolation: isolate;
  overflow: hidden;

  .card-header {
    font-size: revert;
    position: absolute;
  }

  .profile-films-viewed-graphic {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .profile-info-layered-over-graphic {
    .name-text {
      display: block;
      margin-top: 0.5em;
      font-weight: 600;
      font-size: x-large;
    }
  }

  margin-top: 20px;
  padding: 10px;
}

.films-viewed-card {

  .btn-show-more {
    position: absolute;
    right: 1em;
    font-size: small;
    color: var(--bs-secondary);
    opacity: 80%;
  }

  .card-body {

    padding-top: 5px;
    overflow-y: hidden;

    .decade {
      border-style: solid none none none;
      border-width: 1px;
      border-color: var(--bs-card-border-color);
      padding-top: 0.5em;
      margin-top: 0.5em;
    }

    .film-list {
      margin-top: 1em;
      columns: 3 auto;
      text-align: left;
      font-size: xx-small;
    }

    &.expanded {
      max-height: auto;
    }

    &.not-expanded {
      max-height: 200px;
    }
  }

  .card-footer {
    background-color: inherit;
    border-top: none;
    height: 3em;
  }
}


</style>