<template>
  <img :src="getProfilePicUrlForUser(user)" :height=size class="profile-pic" referrerPolicy="no-referrer">
</template>


<script>

export default {
  name: "ProfilePicture",
  props: ['user', 'size'],
  data() {
    return {
    }
  },
  methods: {
    getProfilePicUrlForUser() {

      if ( !this.user.picture_url ) {
        return "";
      }
      return `${this.user.picture_url}?t=${Date.now()}`;
    },
  },
}
</script>


<style scoped>

.profile-picture-component {
  position: relative;
  width: fit-content;
  /*background-color: #01b4e4;*/
}

img {
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1px;
  border-color: lightgrey;
}

</style>