<template>
  <span>
    <span>{{user.username}}</span>
    <a class="edit-profile-name" href="#" title="edit profile name" @click.prevent="showUpdateUsernameModal()">
      <i class="fa fa-pencil-square-o"></i>
    </a>
  </span>


<!--  modal for updating username -->
  <section class="modal fade" id="update-username-modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5>update username</h5>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitUsernameChange">
            <div class="input-group">
              <input type="text" class="form-control" v-model="editUsername" aria-label="Recipient's username with two button addons" aria-describedby="button-addon1" >
              <button class="btn btn-outline-success" type="submit" data-bs-title="Accept">
                <i class="fa fa-check"></i>
              </button>
              <button class="btn btn-outline-danger" type="button" data-bs-title="Reject" @click="updateUsernameModal.hide()">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import {Modal} from "bootstrap";

export default {
  name: "EditableProfileName",
  computed: {
    user() {
      return userStore.user;
    }
  },
  data() {
    return {
      show_modal: false,
      editUsername: userStore.user?.username ? userStore.user.username : '',
      waitingForResponse: false,
      updateUsernameModal: null,
    }
  },
  async created() {
  },
  mounted() {
    this.updateUsernameModal = new Modal('#update-username-modal', {});
    this.updateUsernameModal.hide();
  },
  methods: {
    showUpdateUsernameModal() {
      this.editUsername = this.user.username;
      this.updateUsernameModal?.show();
    },

    async submitUsernameChange() {

      this.waitingForResponse = true;
      axios.post(`/api/user/update/username/${this.editUsername}`)
          .then((response) => {
            userStore.setUser(response.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.waitingForResponse = false;
            this.updateUsernameModal.hide();
          })
    }
  }
}
</script>


<style scoped>

.form-control {
  display: inline;
  width: 200px;
}

.edit-profile-name {
  margin-top: -0.5ex;
  margin-left: 0.2em;
  position: absolute;
  color: var(--bs-card-border-color);
  text-shadow:  -1px -1px 0px white;

  &:hover {
    color: dimgrey;
  }
}


</style>