<template>
  <div v-if="user && userAuthInfo" class="container text-center">
    <div class="row">
      <div class="col-12">
        <h1>Settings</h1>
        <hr>
      </div>
    </div>

    <div class="row">

<!-- profile card -->
      <div class="col-12 col-lg-6">
        <div class="card profile-card">
          <div class="card-header">
            <h2>Profile</h2>
          </div>

          <div class="card-body">
            <div class="profile-pic">
                <EditableProfilePicture :user=user :size="100" :editable="true"></EditableProfilePicture>
            </div>
            <div class="name-text">
              <b>username</b>
              <br>
              <EditableProfileName></EditableProfileName>
            </div>
          </div>
        </div>
      </div>

<!-- account card -->
      <div class="col-12 col-lg-6">
        <div class="card account-card">
          <div class="card-header">
            <h2>Account</h2>
          </div>

          <div class="card-body">
            <div class="name-text">
              <b>registered email</b>
              <br>
              {{userAuthInfo.email}}
            </div>
            <div class="name-text">
              <b>password</b>
              <br>
              <router-link class="edit-password" href="#" title="update password" to="/reset-password">
                <span v-text="userAuthInfo.isEmailAuthed? 'change password' : 'set password'" aria-hidden="true"></span><i class="fa fa-pencil-square-o"></i>
              </router-link>
            </div>
            <div class="name-text">
              <b>account created</b>
              <br>
              {{this.userAuthInfo.accountCreatedDate.toLocaleDateString()}}
            </div>
            <div v-if="isAdmin || isTester" class="name-text">
              <b>special roles</b>
              <br>
              <div v-if="isAdmin" class="admin-text"><i class="fa fa-wrench"></i> admin</div>
              <div v-if="isTester" class="tester-text"><i class="fa fa-heartbeat"></i> tester</div>
            </div>


          </div>

        </div>
      </div>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import EditableProfilePicture from "@/components/profile/EditableProfilePicture.vue";
import EditableProfileName from "@/components/profile/EditableProfileName.vue";

export default {
  components: {EditableProfileName, EditableProfilePicture},
  name: "SettingsPage",
  computed: {
    user() {
      return userStore.user;
    },
    isAdmin() {
      return userStore.isAdmin();
    },
    isTester() {
      return userStore.isTester();
    }
  },
  data() {
    return {
      waitingForResponse: false,
      userAuthInfo: null,
      logoPath: `${process.env.BASE_URL}logos/`,
      showCropper: false,
    }
  },
  async created() {
    axios
        .get("/api/user/auth-info")
        .then((response) => {
          console.log(response.data);
          this.userAuthInfo = response.data;

          const kMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          const creationDateObj = new Date(this.userAuthInfo.accountCreatedDate);
          this.userAuthInfo.accountCreatedDate = creationDateObj;
          this.userAuthInfo.accountCreatedDateText = `${kMonthNames[creationDateObj.getMonth()]} ${creationDateObj.getFullYear()}`;
          console.log(response.data);
        }, (error) => {
          console.log(error);
        });
  },
  methods: {
    handleUploaded(event) {
      console.log('avatar uploaded', event)
    },
    showUpdateUsernameModal() {
      this.editUsername = this.user.username;
      this.updateUsernameModal?.show();
    },
  }
}
</script>


<style scoped>

.card {
  margin-top: 20px;
  margin-bottom: 20px;
}

.name-text {
  margin: 1em;
}

.profile-card {

  .profile-pic {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}

.account-card {

  .admin-text {
    color: crimson;
  }
  .tester-text {
    color: blue;
  }

  .edit-password {

    border-radius: 1em;
    border-style: solid;
    border-width: 1px;
    border-color: var(--bs-card-border-color);
    padding: 0em 0.4em 0.2em 0.4em;

    text-decoration: inherit;
    color: grey;
    cursor: pointer;

    i {
      margin-top: -0.5ex;
      margin-left: 0.2em;
      position: absolute;
      text-shadow:  -1px -1px 0px white;
      color: var(--bs-card-border-color);
    }

    &:hover {
      color: dimgrey;
      border-color: dimgrey;

      i {
        color: dimgrey;
      }

    }



  }

  .btn {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

}


</style>