<template>
  <FriendCard :friend="friend" :link_to_friends_profile="link_to_friends_profile"> </FriendCard>
</template>


<script>
import FriendCard from "@/components/friends/cards/FriendCard.vue";

export default {
  name: 'FriendCard_Stranger',
  components: {FriendCard},
  props: ['friend', 'link_to_friends_profile'],
  emits: ['update-friends-list'],
  methods: {
  }

}
</script>


<style scoped>

</style>