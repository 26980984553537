
export const languageData = {

    getDataObject(_lang_code) {
        return this.data.find((dataObj) => {
            return (_lang_code?.toLowerCase() === dataObj.language_code.toLowerCase());
        });
    },

    getFlagCode(_lang_code) {
        return this.getDataObject(_lang_code)?.flag_code.toLowerCase();
    },

    getLanguageName(_lang_code) {
        return this.getDataObject(_lang_code)?.language.toLowerCase();
    },

    // our array of data objects
    data: [
        // confirmed used by our films
        { language: 'Arabic', language_code: 'ar', flag_code: 'arab' }, // arabic => flag of arab league
        { language: 'Bosnian',language_code: 'bs', flag_code: 'ba' }, // bosnian => flag of bosnia
        { language: 'Cantonese', language_code: 'cn', flag_code: 'cn' }, // cantonese => flag of china
        { language: 'Croatian', language_code: 'hr', flag_code: 'hr' }, // croatian => flag of croatia
        { language: 'Czech', language_code: 'cs', flag_code: 'cz' }, // czech => flag of czech republic
        { language: 'Welsh', language_code: 'cy', flag_code: 'gb-wls' }, // welsh => flag of wales
        { language: 'Danish', language_code: 'da', flag_code: 'dk' }, // danish => flag of denmark
        { language: 'German', language_code: 'de', flag_code: 'de' }, // german => flag of germany
        { language: 'Dzongkha', language_code: 'dz', flag_code: 'bt' }, // dzongkha => flag of bhutan
        { language: 'Greek', language_code: 'el', flag_code: 'gr' }, // greek => flag of greece
        { language: 'English', language_code: 'en', flag_code: 'us' }, // english => flag of us
        { language: 'English', language_code: 'en-GB', flag_code: 'us' }, // english => flag of us
        { language: 'Spanish', language_code: 'es', flag_code: 'es' }, // spanish => flag of spain
        { language: 'Estonian', language_code: 'et', flag_code: 'ee' }, // estonian => flag of estonia
        { language: 'Persian', language_code: 'fa', flag_code: 'ir' }, // persian => flag of iran
        { language: 'Finnish', language_code: 'fi', flag_code: 'fi' }, // finnish => flag of finland
        { language: 'French', language_code: 'fr', flag_code: 'fr' }, // french => flag of france
        { language: 'Irish', language_code: 'ga', flag_code: 'ie' }, // irish => flag of ireland
        { language: 'Hebrew', language_code: 'he', flag_code: 'il' }, // hebrew => flag of israel
        { language: 'Hindi', language_code: 'hi', flag_code: 'in' }, // hindi => flag of india
        { language: 'Hungarian', language_code: 'hu', flag_code: 'hu' }, // hungarian => flag of hungary
        { language: 'Indonesian', language_code: 'id', flag_code: 'id' }, // indonesian => flag of indonesia
        { language: 'Icelandic', language_code: 'is', flag_code: 'is' }, // icelandic => flag of iceland
        { language: 'Italian', language_code: 'it', flag_code: 'it' }, // italian => flag of italy
        { language: 'Japanese', language_code: 'ja', flag_code: 'jp' }, // japanese => flag of japan
        { language: 'Georgian', language_code: 'ka', flag_code: 'ge' }, // georgian => flag of georgia
        { language: 'Kalaallisut', language_code: 'kl', flag_code: 'gl' }, // kalaallisut => flag of greenland
        { language: 'Korean', language_code: 'ko', flag_code: 'kr' }, // korean => flag of korea
        { language: 'Kirghiz', language_code: 'ky', flag_code: 'kg' }, // kirghiz => flag of kyrgyzstan
        { language: 'Latvian', language_code: 'lv', flag_code: 'lv' }, // latvian => flag of latvia
        { language: 'Macedonian', language_code: 'mk', flag_code: 'mk' }, // macedonian => flag of north macedonia
        { language: 'Mongolian', language_code: 'mn', flag_code: 'mn' }, // mongolian => flag of mongolia
        { language: 'Dutch', language_code: 'nl', flag_code: 'nl' }, // dutch => flag of netherlands
        { language: 'Norwegian', language_code: 'no', flag_code: 'no' }, // norwegian => flag of norway
        { language: 'Punjabi', language_code: 'pa', flag_code: 'punjab' }, // punjabi => flag of punjab
        { language: 'Polish', language_code: 'pl', flag_code: 'pl' }, // polish => flag of poland
        { language: 'Pushto', language_code: 'ps', flag_code: 'ps' }, // pushto => flag of afghanistan
        { language: 'Portuguese', language_code: 'pt', flag_code: 'pt' }, // portuguese => flag of portugal
        { language: 'Romanian', language_code: 'ro', flag_code: 'ro' }, // romanian => flag of romania
        { language: 'Russian', language_code: 'ru', flag_code: 'ru' }, // russian => flag of russia
        { language: 'Northern Sami', language_code: 'se', flag_code: 'se' }, // northern sami => flag of sweden (though it's spoken in norway and finland too)
        { language: 'Serbo-Croatian', language_code: 'sh', flag_code: 'yugo' }, // serbo-croatian => flag of yugoslavia
        { language: 'Slovak', language_code: 'sk', flag_code: 'sk' }, // slovak => flag of slovakia
        { language: 'Albanian', language_code: 'sq', flag_code: 'al' }, // albanian => flag of albania
        { language: 'Serbian', language_code: 'sr', flag_code: 'rs' }, // serbian => flag of serbia
        { language: 'Swedish', language_code: 'sv', flag_code: 'se' }, // swedish => flag of sweden
        { language: 'Swahili', language_code: 'sw', flag_code: 'ke' }, // swahili => flag of kenya
        { language: 'Tamil', language_code: 'ta', flag_code: 'in' }, // tamil => flag of india
        { language: 'Telugu', language_code: 'te', flag_code: 'in' }, // telugu => flag of india
        { language: 'Tswana', language_code: 'tn', flag_code: 'bw' }, // tswana => flag of botswana
        { language: 'Turkish', language_code: 'tr', flag_code: 'tr' }, // turkish => flag of turkey
        { language: 'Ukrainian', language_code: 'uk', flag_code: 'ua' }, // ukrainian => flag of ukraine
        { language: 'Vietnamese', language_code: 'vi', flag_code: 'vn' }, // vietnamese => flag of vietnam
        { language: 'No Language', language_code: 'xx', flag_code: 'xx' }, // no language => xx provided is solid white with a grey x
        { language: 'Mandarin', language_code: 'zh', flag_code: 'cn' }, // mandarin => flag of china
        { language: 'Xhosa', language_code: 'xh', flag_code: 'za' }, // xhosa => flag of South Africa

        { language: 'Zulu', language_code: 'zu', flag_code: 'za' }, // zulu => flag of south africa

        // not used by our films as of 2023, but probably worth filling out
        { language: 'Afar', language_code: 'aa', flag_code: '' }, // afar => flag of ?

        { language: 'Abkhazian', language_code: 'ab', flag_code: '' }, // abkhazian => flag of ?

        { language: 'Avestan', language_code: 'ae', flag_code: '' }, // avestan => flag of ?

        { language: 'Afrikaans', language_code: 'af', flag_code: '' }, // afrikaans => flag of ?

        { language: 'Akan', language_code: 'ak', flag_code: '' }, // akan => flag of ?

        { language: 'Amharic', language_code: 'am', flag_code: '' }, // amharic => flag of ?

        { language: 'Aragonese', language_code: 'an', flag_code: '' }, // aragonese => flag of ?

        { language: 'Assamese', language_code: 'as', flag_code: '' }, // assamese => flag of ?

        { language: 'Avaric', language_code: 'av', flag_code: '' }, // avaric => flag of ?

        { language: 'Aymara', language_code: 'ay', flag_code: '' }, // aymara => flag of ?

        { language: 'Azerbaijani', language_code: 'az', flag_code: '' }, // azerbaijani => flag of ?

        { language: 'Bashkir', language_code: 'ba', flag_code: '' }, // bashkir => flag of ?

        { language: 'Belarusian', language_code: 'be', flag_code: '' }, // belarusian => flag of ?

        { language: 'Bulgarian', language_code: 'bg', flag_code: '' }, // bulgarian => flag of ?

        { language: 'Bislama', language_code: 'bi', flag_code: '' }, // bislama => flag of ?

        { language: 'Bambara', language_code: 'bm', flag_code: '' }, // bambara => flag of ?

        { language: 'Bengali', language_code: 'bn', flag_code: '' }, // bengali => flag of ?

        { language: 'Tibetan', language_code: 'bo', flag_code: '' }, // tibetan => flag of ?

        { language: 'Breton', language_code: 'br', flag_code: '' }, // breton => flag of ?

        { language: 'Catalan', language_code: 'ca', flag_code: '' }, // catalan => flag of ?

        { language: 'Chechen', language_code: 'ce', flag_code: '' }, // chechen => flag of ?

        { language: 'Chamorro', language_code: 'ch', flag_code: '' }, // chamorro => flag of ?

        { language: 'Corsican', language_code: 'co', flag_code: '' }, // corsican => flag of ?

        { language: 'Cree', language_code: 'cr', flag_code: '' }, // cree => flag of ?

        { language: 'Slavic', language_code: 'cu', flag_code: '' }, // slavic => flag of ?

        { language: 'Chuvash', language_code: 'cv', flag_code: '' }, // chuvash => flag of ?

        { language: 'Divehi', language_code: 'dv', flag_code: '' }, // divehi => flag of ?

        { language: 'Ewe', language_code: 'ee', flag_code: '' }, // ewe => flag of ?

        { language: 'Esperanto', language_code: 'eo', flag_code: '' }, // esperanto => flag of ?

        { language: 'Basque', language_code: 'eu', flag_code: '' }, // basque => flag of ?

        { language: 'Fulah', language_code: 'ff', flag_code: '' }, // fulah => flag of ?

        { language: 'Fijian', language_code: 'fj', flag_code: '' }, // fijian => flag of ?

        { language: 'Faroese', language_code: 'fo', flag_code: '' }, // faroese => flag of ?

        { language: 'Frisian', language_code: 'fy', flag_code: '' }, // frisian => flag of ?

        { language: 'Gaelic', language_code: 'gd', flag_code: '' }, // gaelic => flag of ?

        { language: 'Galician', language_code: 'gl', flag_code: '' }, // galician => flag of ?

        { language: 'Guarani', language_code: 'gn', flag_code: '' }, // guarani => flag of ?

        { language: 'Gujarati', language_code: 'gu', flag_code: '' }, // gujarati => flag of ?

        { language: 'Manx', language_code: 'gv', flag_code: '' }, // manx => flag of ?

        { language: 'Hausa', language_code: 'ha', flag_code: '' }, // hausa => flag of ?

        { language: 'Hiri Motu', language_code: 'ho', flag_code: '' }, // hiri motu => flag of ?

        { language: 'Haitian; Haitian Creole', language_code: 'ht', flag_code: '' }, // haitian; haitian creole => flag of ?

        { language: 'Armenian', language_code: 'hy', flag_code: '' }, // armenian => flag of ?

        { language: 'Herero', language_code: 'hz', flag_code: '' }, // herero => flag of ?

        { language: 'Interlingua', language_code: 'ia', flag_code: '' }, // interlingua => flag of ?

        { language: 'Interlingue', language_code: 'ie', flag_code: '' }, // interlingue => flag of ?

        { language: 'Igbo', language_code: 'ig', flag_code: '' }, // igbo => flag of ?

        { language: 'Yi', language_code: 'ii', flag_code: '' }, // yi => flag of ?

        { language: 'Inupiaq', language_code: 'ik', flag_code: '' }, // inupiaq => flag of ?

        { language: 'Ido', language_code: 'io', flag_code: '' }, // ido => flag of ?

        { language: 'Inuktitut', language_code: 'iu', flag_code: '' }, // inuktitut => flag of ?

        { language: 'Javanese', language_code: 'jv', flag_code: '' }, // javanese => flag of ?

        { language: 'Kongo', language_code: 'kg', flag_code: '' }, // kongo => flag of ?

        { language: 'Kikuyu', language_code: 'ki', flag_code: '' }, // kikuyu => flag of ?

        { language: 'Kuanyama', language_code: 'kj', flag_code: '' }, // kuanyama => flag of ?

        { language: 'Kazakh', language_code: 'kk', flag_code: '' }, // kazakh => flag of ?

        { language: 'Khmer', language_code: 'km', flag_code: '' }, // khmer => flag of ?

        { language: 'Kannada', language_code: 'kn', flag_code: '' }, // kannada => flag of ?

        { language: 'Kanuri', language_code: 'kr', flag_code: '' }, // kanuri => flag of ?

        { language: 'Kashmiri', language_code: 'ks', flag_code: '' }, // kashmiri => flag of ?

        { language: 'Kurdish', language_code: 'ku', flag_code: '' }, // kurdish => flag of ?

        { language: 'Komi', language_code: 'kv', flag_code: '' }, // komi => flag of ?

        { language: 'Cornish', language_code: 'kw', flag_code: '' }, // cornish => flag of ?

        { language: 'Latin', language_code: 'la', flag_code: '' }, // latin => flag of ?

        { language: 'Letzeburgesch', language_code: 'lb', flag_code: '' }, // letzeburgesch => flag of ?

        { language: 'Ganda', language_code: 'lg', flag_code: '' }, // ganda => flag of ?

        { language: 'Limburgish', language_code: 'li', flag_code: '' }, // limburgish => flag of ?

        { language: 'Lingala', language_code: 'ln', flag_code: '' }, // lingala => flag of ?

        { language: 'Lao', language_code: 'lo', flag_code: '' }, // lao => flag of ?

        { language: 'Lithuanian', language_code: 'lt', flag_code: '' }, // lithuanian => flag of ?

        { language: 'Luba-Katanga', language_code: 'lu', flag_code: '' }, // luba-katanga => flag of ?



        { language: 'Malagasy', language_code: 'mg', flag_code: '' }, // malagasy => flag of ?

        { language: 'Marshall', language_code: 'mh', flag_code: '' }, // marshall => flag of ?

        { language: 'Maori', language_code: 'mi', flag_code: '' }, // maori => flag of ?

        { language: 'Malayalam', language_code: 'ml', flag_code: '' }, // malayalam => flag of ?

        { language: 'Moldavian', language_code: 'mo', flag_code: '' }, // moldavian => flag of ?

        { language: 'Marathi', language_code: 'mr', flag_code: '' }, // marathi => flag of ?

        { language: 'Malay', language_code: 'ms', flag_code: '' }, // malay => flag of ?

        { language: 'Maltese', language_code: 'mt', flag_code: '' }, // maltese => flag of ?

        { language: 'Burmese', language_code: 'my', flag_code: '' }, // burmese => flag of ?

        { language: 'Nauru', language_code: 'na', flag_code: '' }, // nauru => flag of ?

        { language: 'Norwegian Bokmål', language_code: 'nb', flag_code: '' }, // norwegian bokmål => flag of ?

        { language: 'Ndebele', language_code: 'nd', flag_code: '' }, // ndebele => flag of ?

        { language: 'Nepali', language_code: 'ne', flag_code: '' }, // nepali => flag of ?

        { language: 'Ndonga', language_code: 'ng', flag_code: '' }, // ndonga => flag of ?

        { language: 'Norwegian Nynorsk', language_code: 'nn', flag_code: '' }, // norwegian nynorsk => flag of ?

        { language: 'Ndebele', language_code: 'nr', flag_code: '' }, // ndebele => flag of ?

        { language: 'Navajo', language_code: 'nv', flag_code: '' }, // navajo => flag of ?

        { language: 'Chichewa; Nyanja', language_code: 'ny', flag_code: '' }, // chichewa; nyanja => flag of ?

        { language: 'Occitan', language_code: 'oc', flag_code: '' }, // occitan => flag of ?

        { language: 'Ojibwa', language_code: 'oj', flag_code: '' }, // ojibwa => flag of ?

        { language: 'Oromo', language_code: 'om', flag_code: '' }, // oromo => flag of ?

        { language: 'Oriya', language_code: 'or', flag_code: '' }, // oriya => flag of ?

        { language: 'Ossetian; Ossetic', language_code: 'os', flag_code: '' }, // ossetian; ossetic => flag of ?

        { language: 'Pali', language_code: 'pi', flag_code: '' }, // pali => flag of ?

        { language: 'Quechua', language_code: 'qu', flag_code: '' }, // quechua => flag of ?

        { language: 'Raeto-Romance', language_code: 'rm', flag_code: '' }, // raeto-romance => flag of ?

        { language: 'Rundi', language_code: 'rn', flag_code: '' }, // rundi => flag of ?

        { language: 'Kinyarwanda', language_code: 'rw', flag_code: '' }, // kinyarwanda => flag of ?

        { language: 'Sanskrit', language_code: 'sa', flag_code: '' }, // sanskrit => flag of ?

        { language: 'Sardinian', language_code: 'sc', flag_code: '' }, // sardinian => flag of ?

        { language: 'Sindhi', language_code: 'sd', flag_code: '' }, // sindhi => flag of ?

        { language: 'Sango', language_code: 'sg', flag_code: '' }, // sango => flag of ?

        { language: 'Sinhalese', language_code: 'si', flag_code: '' }, // sinhalese => flag of ?

        { language: 'Slovenian', language_code: 'sl', flag_code: '' }, // slovenian => flag of ?

        { language: 'Samoan', language_code: 'sm', flag_code: '' }, // samoan => flag of ?

        { language: 'Shona', language_code: 'sn', flag_code: '' }, // shona => flag of ?

        { language: 'Somali', language_code: 'so', flag_code: '' }, // somali => flag of ?

        { language: 'Swati', language_code: 'ss', flag_code: '' }, // swati => flag of ?

        { language: 'Sotho', language_code: 'st', flag_code: '' }, // sotho => flag of ?

        { language: 'Sundanese', language_code: 'su', flag_code: '' }, // sundanese => flag of ?

        { language: 'Tajik', language_code: 'tg', flag_code: '' }, // tajik => flag of ?

        { language: 'Thai', language_code: 'th', flag_code: '' }, // thai => flag of ?

        { language: 'Tigrinya', language_code: 'ti', flag_code: '' }, // tigrinya => flag of ?

        { language: 'Turkmen', language_code: 'tk', flag_code: '' }, // turkmen => flag of ?

        { language: 'Tagalog', language_code: 'tl', flag_code: '' }, // tagalog => flag of ?

        { language: 'Tonga', language_code: 'to', flag_code: '' }, // tonga => flag of ?

        { language: 'Tsonga', language_code: 'ts', flag_code: '' }, // tsonga => flag of ?

        { language: 'Tatar', language_code: 'tt', flag_code: '' }, // tatar => flag of ?

        { language: 'Twi', language_code: 'tw', flag_code: '' }, // twi => flag of ?

        { language: 'Tahitian', language_code: 'ty', flag_code: '' }, // tahitian => flag of ?

        { language: 'Uighur', language_code: 'ug', flag_code: '' }, // uighur => flag of ?

        { language: 'Urdu', language_code: 'ur', flag_code: '' }, // urdu => flag of ?

        { language: 'Uzbek', language_code: 'uz', flag_code: '' }, // uzbek => flag of ?

        { language: 'Venda', language_code: 've', flag_code: '' }, // venda => flag of ?

        { language: 'Volapük', language_code: 'vo', flag_code: '' }, // volapük => flag of ?

        { language: 'Walloon', language_code: 'wa', flag_code: '' }, // walloon => flag of ?

        { language: 'Wolof', language_code: 'wo', flag_code: '' }, // wolof => flag of ?

        { language: 'Yiddish', language_code: 'yi', flag_code: '' }, // yiddish => flag of ?

        { language: 'Yoruba', language_code: 'yo', flag_code: '' }, // yoruba => flag of ?

        { language: 'Zhuang', language_code: 'za', flag_code: '' }, // zhuang => flag of ?
    ]
};
