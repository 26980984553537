<template>
  <section class="profile-picture-component">
    <ProfilePicture :user="user" :size="size"></ProfilePicture>

    <a v-if="editable" class="edit-button" href="#" title="edit profile pic" @click.prevent="showEditModal()">
      <i class="fa fa-pencil-square-o"></i>
    </a>

    <GenericModal v-if="show_edit_modal" @modal-closed="show_edit_modal=false">
      <template #header>
        <h6>edit profile pic</h6>
      </template>

      <div v-if="displayDefaultImage" style="text-align: center;">
        <img :src="getDefaultProfilePicUrlForUser()" :height=256 referrerPolicy="no-referrer">
      </div>

      <div v-else>
        <cropper
            ref="cropper"
            class="cropper"
            backgroundClass="cropper-background"
            :src="loadedImage? loadedImage : getProfilePicUrlForUser()"
            :canvas="{ height: 256, width: 256 }"
            :stencil-props="{ aspectRatio: 1 }"
            @change="onCropperChange"
            @ready="onCropperReady"
            @error="onCropperError"
        />
      </div>

      <div>
<!--        load image button-->
        <div class="load-image-button d-grid gap-2">
          <input class="btn btn-sm btn-block btn-outline-secondary" type="file" ref="file" @change="loadImage($event)" accept="image/png, image/jpeg, image/bmp">
        </div>

<!--        set default image button-->
        <div v-if="!displayDefaultImage" class="default-image-button d-grid gap-2 btn btn-outline-secondary" type="button" @click="setDefaultImage()">
          <span>set default image <img :src="getDefaultProfilePicUrlForUser()" :height=10 referrerPolicy="no-referrer"></span>
        </div>
      </div>

      <div class="bottom-buttons">
        <button class="btn btn-sm btn-outline-secondary me-2" type="button" data-bs-dismiss="modal">
          cancel
        </button>
        <button class="btn btn-sm btn-success" type="button" data-bs-dismiss="modal" @click="updateImage()">
          update picture
        </button>
      </div>

    </GenericModal>



  </section>
</template>


<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import GenericModal from "@/components/modal/GenericModal.vue";
import axios from "axios";
import { userStore } from "@/userStore";
import ProfilePicture from "@/components/profile/ProfilePicture.vue";

export default {
  name: "EditableProfilePicture",
  computed: {
    userStore() {
      return userStore
    },
    displayDefaultImage() {
      if ( this.loadedImage ) {
        return false;
      }
      if ( this.previewDefaultImage ) {
        return true;
      }
      if ( this.isUserUsingDefaultProfilePic() ) {
        return true;
      }
      return false;
    }
  },
  components: {
    ProfilePicture,
    GenericModal,
    Cropper,
  },
  props: ['user', 'size', 'editable'],
  data() {
    return {
      show_edit_modal: false,
      loadedImage: null,
      croppedImage: null,
      previewDefaultImage: false
    }
  },
  methods: {
    getProfilePicUrlForUser() {
      if ( !this.user.picture_url ) {
        return "";
      }
      return `${this.user.picture_url}?t=${Date.now()}`;
    },
    getDefaultProfilePicUrlForUser() {
      if ( !this.user.default_picture_url ) {
        return ""
      }
      return `${this.user.default_picture_url}?t=${Date.now()}`;
    },
    isUserUsingDefaultProfilePic() {
      return ( this.user.picture_url === this.user.default_picture_url );
    },
    onCropperChange(/*{coordinates, canvas}*/) {
      //console.log(coordinates, canvas);
    },
    showEditModal() {
      this.clearLoadedImage();
      this.previewDefaultImage = false;
      this.show_edit_modal = true;
    },

    onCropperReady() {
      //console.log('image successfully loaded');
    },
    onCropperError() {
      //console.log('error loading image');
    },
    async getPngBlobFromCanvas(_canvas) {
      return new Promise((resolve, reject) => {
        if ( !_canvas?.toBlob ) {
          reject(new Error('getBlobFromCanvas() called with bad canvas argument.'));
        } else {
          _canvas.toBlob((blob) => {
            if ( blob ) {
              resolve(blob);
            } else {
              reject(new Error('canvas.toBlob() failed to create a blob'));
            }
          }, 'image/png');
        }
      });
    },

    async updateImage() {

      if ( this.displayDefaultImage ) {

        // if we're setting the default image, but this user is already using the default image, don't do anything.
        if ( this.isUserUsingDefaultProfilePic() ) {
          return;
        }

        // if we're clearing the profile pic, post with an empty body
        return axios.post('/api/user/clear-profile-pic')
            .then((response) => {
              userStore.setUser(response.data);
            })
            .catch((err) => {
              console.log(err);
            });
    }

      const { canvas } = this.$refs.cropper.getResult();
      this.croppedImage = canvas.toDataURL();

      return this.getPngBlobFromCanvas(canvas)
          .then((blob) => {
            const form = new FormData();
            form.append('pic', blob);
            return axios.post('/api/user/update-profile-pic', form);
          })
          .then((response) => {
            userStore.setUser(response.data);
          })
          .catch((err) => {
            console.log(err);
          });


    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      const imageFile = files ? files[0] : null;
      if ( imageFile ) {
        //console.log(`reading "${imageFile.name}" `);

        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        this.clearLoadedImage();

        // 2. Create the blob link to the file to optimize performance:
        this.loadedImage = URL.createObjectURL(imageFile);
      }
    },

    clearLoadedImage() {
      if (this.loadedImage) {
        URL.revokeObjectURL(this.loadedImage);
        this.loadedImage = null;
      }
    },

    setDefaultImage() {
      //console.log('setDefaultImage()');
      this.clearLoadedImage();
      this.previewDefaultImage = true;
    }
  },
}
</script>


// UNSCOPED style
<style>
.cropper-background {
  background:
      repeating-conic-gradient(#d7d7d7 0% 25%, transparent 0% 50%)
      50% / 16px 16px;

}
</style>



<style scoped>

.profile-picture-component {
  position: relative;
  width: fit-content;
  /*background-color: #01b4e4;*/
}

img {
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1px;
  border-color: lightgrey;
}

.edit-button {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 20px;

  color: var(--bs-card-border-color);
  text-shadow:  -1px -1px 0px white;

  &:hover {
    color: dimgrey;
  }

}

.cropper {
  /*
  border-style: solid;
  border-color: red;
  border-width: 5px;
   */
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 50vh;
}

.load-image-button {
  margin-top: 1em;
  text-align: center;

  input {
    width: 100%;
  }

}
.default-image-button {
  margin-top: 1em;
  text-align: center;

  img {
    height: 1em;
  }
}

.bottom-buttons {
  margin-top: 3em;
  text-align: right;
}

</style>


