import { createApp } from 'vue'
import App from './App.vue'
import * as VueRouter from 'vue-router'
import VueGtag, {query as gaQuery} from "vue-gtag";
//import { config as vueGtagConfig } from "vue-gtag";
//import { set as gaSet } from "vue-gtag";
import WelcomePage from './pages/WelcomePage.vue';
import DashboardPage from "@/pages/DashboardPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import AdminFilms from "@/pages/admin/AdminFilms.vue";
import SearchPage from "@/pages/SearchPage.vue";
import SearchResultsPage from "@/pages/SearchResultsPage.vue";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import ViewingOptionsPage from "@/pages/ViewingOptionsPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import AdminPage from "@/pages/AdminPage.vue";
import AdminUsersPage from "@/pages/admin/AdminUsersPage.vue";
import SetCategoryWinnersPage from "@/pages/admin/SetCategoryWinnersPage.vue";
import FriendsPage from "@/pages/friends/FriendsPage.vue";
import FriendConnectPage from "@/pages/friends/FriendConnectPage.vue";
import {userStore} from "@/userStore";
import axios from "axios";
import AboutPage from "@/pages/AboutPage.vue";
import ChecklistPage from "@/pages/ChecklistPage.vue";
import ResetPasswordPage from "@/pages/ResetPasswordPage.vue";
import SettingsPage from "@/pages/SettingsPage.vue";
import LogInPage from "@/pages/LogInPage.vue";
import AdminMacrodataRefinement from "@/pages/admin/AdminMacrodataRefinement.vue";
import AdminEditFilm from "@/pages/admin/AdminEditFilm.vue";
import FriendPage from "@/pages/friends/FriendPage.vue";

const GTAG_ID = 'G-8F170E7THW'


const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(process.env.BASE_URL),
    routes: [{
        path: '/',
        redirect: { name: 'DashboardPage' }
    }, {
        name: "WelcomePage",
        path: '/welcome',
        component: WelcomePage,
        beforeEnter: [requireNoUser],
    }, {
        name: "LogInPage",
        path: '/log-in',
        component: LogInPage,
        beforeEnter: [requireNoUser],
    }, {
        name: "ResetPasswordPage",
        path: '/reset-password',
        component: ResetPasswordPage,
        beforeEnter: [preferUser],
    }, {
        name: "AboutPage",
        path: '/about',
        component: AboutPage,
        beforeEnter: [preferUser],
    }, {
        name: "AuthComplete",
        path: '/auth-complete',
        redirect: authCompleteRedirect
    }, {
        name: "DashboardPage",
        path: '/dashboard',
        component: DashboardPage,
        beforeEnter: [requireUser],
    }, {
        name: "ProfilePage",
        path: '/profile',
        component: ProfilePage,
        beforeEnter: [requireUser],
    }, {
        name: "SettingsPage",
        path: '/settings',
        component: SettingsPage,
        beforeEnter: [requireUser],
    }, {
        name: "ChecklistPage",
        path: '/checklist/:init_ceremony?',
        component: ChecklistPage,
        beforeEnter: [requireUser],
        props: true
    }, {
        name: "ViewingOptionsPage",
        path: '/viewing-options',
        component: ViewingOptionsPage,
        beforeEnter: [requireUser],
    }, {
        name: "SearchPage",
        path: '/search',
        component: SearchPage,
        beforeEnter: [requireUser],
    }, {
        name: "SearchResultsPage",
        path: '/search-results',
        component: SearchResultsPage,
        beforeEnter: [requireUser],
    },

    // friends pages
    {
        name: "FriendsPage",
        path: '/friends',
        component: FriendsPage,
        beforeEnter: [requireUser],
    }, {
        name: "FriendConnectPage",
        path: '/connect-with/:user_id_to_connect_with',
        component: FriendConnectPage,
        props: true
    }, {
        name: "FriendPage",
        path: '/friend/:user_id',
        component: FriendPage,
        beforeEnter: [requireUser],
        props: true
    },

    // adnim pages
    {
        name: "AdminPage",
        path: '/admin',
        component: AdminPage,
        beforeEnter: [requireAdmin],
    }, {
        name: "AdminUsersPage",
        path: '/admin/users',
        component: AdminUsersPage,
        beforeEnter: [requireAdmin],
    }, {
        name: "SetCategoryWinnersPage",
        path: '/admin/set-category-winners/:ceremony_num',
        component: SetCategoryWinnersPage,
        beforeEnter: [requireAdmin],
        props: true
    }, {
        name: "AdminMacrodataRefinement",
        path: '/admin/macrodata-refinement',
        component: AdminMacrodataRefinement,
        beforeEnter: [requireAdmin],
        props: true
    }, {
        name: "AdminEditFilm",
        path: '/admin/edit-film/:filmId',
        component: AdminEditFilm,
        beforeEnter: [requireAdmin],
        props: true

    }, {
        path: '/admin/films/:year',
        component: AdminFilms,
        beforeEnter: [requireAdmin],
    }, {
        path: '/:pathMatch(.*)*',
        component: NotFoundPage,
        beforeEnter: [preferUser],
    }]
});

async function requireAdmin(/* to */) {

    if ( userStore.user ) {
        // console.log(`requireAdmin(${to.path}) - already have login`);
        // console.log(`requireAdmin(${to.path}) - isAdmin = ${userStore.isAdmin()}`);
        return userStore.isAdmin();
    }

    //console.log(`requireAdmin(${to.path}) - checking for login`);
    return axios
        .get("/api/user/profile")
        .then((response) => {
            userStore.setUser(response.data);
            // console.log(`requireAdmin(${to.path}) - logged in!`);
            // console.log(`requireAdmin(${to.path}) - isAdmin = ${userStore.isAdmin()}`);
            if ( !userStore.isAdmin() ) {
                return { name: 'DashboardPage' };
            }
            return userStore.isAdmin();
        })
        .catch((/* error */) => {
            //console.log(error);
            userStore.setUser(null);
            //console.log(`requireAdmin(${to.path}) - Not logged in.`);
            return { name: 'WelcomePage' };
        });
}

// eslint-disable-next-line no-unused-vars
async function requireTester(/* to */) {

    if ( userStore.user ) {
        // console.log(`requireAdmin(${to.path}) - already have login`);
        // console.log(`requireAdmin(${to.path}) - isAdmin = ${userStore.isAdmin()}`);
        return userStore.isTester() ? true : { name: 'DashboardPage' };
    }

    //console.log(`requireAdmin(${to.path}) - checking for login`);
    return axios
        .get("/api/user/profile")
        .then((response) => {
            userStore.setUser(response.data);
            // console.log(`requireAdmin(${to.path}) - logged in!`);
            // console.log(`requireAdmin(${to.path}) - isAdmin = ${userStore.isAdmin()}`);
            return userStore.isTester() ? true : { name: 'DashboardPage' };
        })
        .catch((/* error */) => {
            //console.log(error);
            userStore.setUser(null);
            //console.log(`requireAdmin(${to.path}) - Not logged in.`);
            return { name: 'WelcomePage' };
        });
}

function authCompleteRedirect(to) {

    // console.log(`authCompleteRedirect(${to.path}) - checking for login`);
    // console.log(to);

    if ( to.query?.success != 'true' ) {
        // console.log(`authCompleteRedirect() login failure; strategy=${to.query?.strategy ? to.query?.strategy : 'unknown'}`)
        return { name: 'WelcomePage', query: null }; // todo: we need an error page
    }

    if ( sessionStorage.redirectAfterLogin ) {
        const redirect = sessionStorage.redirectAfterLogin;
        delete sessionStorage.redirectAfterLogin;

        // console.log(`authCompleteRedirect() redirectAfterLogin: ${redirect}`)
        return { path: redirect, query: null };
    }

    // console.log(`authCompleteRedirect() redirect: DashboardPage`)
    return { name: 'DashboardPage', query: null };
}

async function preferUser(/* to */) {
    if ( userStore.user ) {
        return true;
    }

    return axios
        .get("/api/user/profile")
        .then((response) => {
            userStore.setUser(response.data);
            return true;
        })
        .catch((/* error */) => {
            userStore.setUser(null);
            return true;
        });
}

async function requireUser(/* to */) {
    if ( userStore.user ) {
        //console.log(`requireUser(${to.path}) - already have login`);
        return true;
    }

    //console.log(`requireUser(${to.path}) - checking for login`);
    return axios
        .get("/api/user/profile")
        .then((response) => {
            userStore.setUser(response.data);
            //console.log(`requireUser(${to.path}) - logged in!`);
            return true;
        })
        .catch((/* error */) => {
            //console.log(error);
            userStore.setUser(null);
            //console.log(`requireUser(${to.path}) - Not logged in.`);
            return { name: 'WelcomePage' };
        });
}

async function requireNoUser(/* to */) {
    if ( userStore.user ) {
        return { name: 'DashboardPage' };
    }

    return axios
        .get("/api/user/profile")
        .then((response) => {
            userStore.setUser(response.data);
            return { name: 'DashboardPage' };
        })
        .catch((/* error */) => {
            //console.log(error);
            userStore.setUser(null);
            return true;
        });
}

function verifyGtagUser() {
    gaQuery('get', GTAG_ID, 'user_id', (gtag_user_id) => {
        if ( userStore.getUserId() != gtag_user_id ) {
            throw Error(`User ID is ${userStore.getUserId()}; but gtag has it set as ${gtag_user_id}`);
        }
        //console.log(`User ID is ${userStore.getUserId()}; gtag user_id is ${gtag_user_id}`)
    });
}

createApp(App)
    .use(router)
    .use(VueGtag, {
            config: { id: GTAG_ID },
            onBeforeTrack () {
                verifyGtagUser();
            },
        }, router)
    .mount('#app')
