<template>
  <ProfilePicture :user="friend" :size="height"></ProfilePicture>
</template>


<script>

import ProfilePicture from "@/components/profile/ProfilePicture.vue";

export default {
  name: "FriendIcon",
  components: {ProfilePicture},
  props: ['friend', 'size'],
  computed: {
    height() {
      return this.size? this.size : 10;
    },
    width() {
      return this.height;
    }

  }
}
</script>


<style scoped>
  img {
    margin-bottom: 4px;
    margin-right: 1px;
    margin-left: 1px;
    border-radius: 50%;
  }
</style>