<template>
  <span class="profile-name">
    <span class="profile-name-text">{{user.username}}</span>
    <span class="viewed-count">{{user.viewed.length}}</span>
  </span>
</template>


<script>

export default {
  name: "ProfileName",
  props: ['user'],
}
</script>


<style scoped>

.profile-name {
  display: inline-block;
  position: relative;
  width: inherit;
  height: fit-content;

  .profile-name-text {
    vertical-align: bottom;
    max-width: 10em;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .viewed-count {
    border-radius: 1em;
    color: #f8f9fa;
    background-color: #b49823;
    padding: 0.1em 0.5em 0.1em 0.5em;

    font-weight: bold;
    //margin-left: 0.5em;
    position: absolute;
    top: -0.5em;


    font-size: 60%;
  }


}


</style>